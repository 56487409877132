<!-- eslint-disable no-unused-vars -->
<style scoped lang="scss">
.li {
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #e8e8e8;
  margin-top: 20px;

  .item-box {
    .item-li {
      &:first-child {
        margin-top: 0px;
      }

      margin-top: 30px;

      .sku-box {
        display: flex;
      }

      .yushou {
        display: inline-block;
        width: 34px;
        height: 18px;
        background: url("~@assets/images/orderlist/orderlistyushou.png") no-repeat;
        background-size: contain;
        transform: translateY(3px);
      }

      .tedian {
        width: 34px;
        height: 18px;
        border-radius: 3px;
        border: 1px solid #f59907;
        font-size: 12px;
        text-align: center;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f59907;
        line-height: 18px;
        display: inline-block;
        transform: translateY(0px);
      }
    }

    .item-li {
      margin-top: 9px;
      padding: 10px;
      display: flex;
      align-items: center;

      .img-p {
        display: flex;

        .img {
          width: 80px;
          height: 80px;
          overflow: hidden;
          border-radius: 4px;
          cursor: pointer;

          img {
            width: 80px;
            height: 80px;
          }
        }
      }

      .name {
        width: 164px;
        height: 72px;
        cursor: pointer;


        .name-text {
          width: 164px;
          font-size: 16px;
          font-family: PingFang SC-中粗体, PingFang SC;
          font-weight: bold;
          color: #1a1a1a;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        margin-left: 20px;
      }

      .content {
        margin-left: 20px;
        width: 100px;
        height: 72px;
        position: relative;

        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 16px;
          font-family: PingFang SC-常规体, PingFang SC;
          font-weight: normal;
          color: #1a1a1a;
          line-height: 24px;
        }

        .tode {
          margin-top: 4px;
          font-size: 16px;
          font-family: PingFang SC-常规体, PingFang SC;
          font-weight: normal;
          color: #00c4a1;
          line-height: 16px;
          cursor: pointer;

        }

        .num {
          position: absolute;
          top: 0;
          right: -20px;
          height: 24px;
          font-size: 16px;
          font-family: PingFang SC-常规体, PingFang SC;
          font-weight: normal;
          color: #1a1a1a;
          line-height: 24px;
          transform: translateX(100%);
        }
      }

      .price-box {
        margin-left: 70px;
        flex: 1;
        height: 72px;

        .p {
          font-size: 16px;
          font-family: PingFang SC-中粗体, PingFang SC;
          font-weight: bolder;
          color: #1a1a1a;
          line-height: 24px;
        }

        .tips {
          font-size: 12px;
          font-family: PingFang SC-常规体, PingFang SC;
          font-weight: normal;
          color: #f5a728;
          line-height: 12px;
          margin-top: 8px;
        }

        .text,
        .pic_p {
          font-size: 16px;
          font-family: PingFang SC-常规体, PingFang SC;
          font-weight: normal;
          color: #1a1a1a;
          line-height: 24px;
        }

        .pic_p {
          font-weight: bold;
        }
      }

      &.gift-box {
        padding: 0;
        padding-left: 10px;

        .img-p {
          display: flex;
          justify-content: right;
          width: 80px;
          height: 80px;

          .img {
            width: 60px;
            height: 60px;
            overflow: hidden;
            border-radius: 4px;

            img {
              width: 60px;
              height: 60px;
            }
          }

          .name {
            height: 48px;
            font-weight: bolder;

            .name-text {
              -webkit-line-clamp: 2;
            }
          }

          .content {
            height: 48px;
          }

          .price-box {
            height: 48px;
          }
        }
      }
    }

    .btn-box {
      height: 68px;
      text-align: right;
      align-items: center;
      padding-right: 0px;
      display: flex;
      align-items: center;
      justify-content: right;
      margin-top: 32px;

      .flex_p {
        flex: 1;
        height: 1px;
      }

      span {
        margin-right: 20px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7f7f7f;
        line-height: 28px;
      }

      .btn {
        min-width: 160px;
        height: 68px;
        border-radius: 8px;
        border: 1px solid #b1b1b1 !important;
        font-size: 28px;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 68px;
        text-align: center;
        padding: 0 24px;
        margin-right: 20px;

        &:last-child {
          margin-right: 9px;
        }

        display: inline-block;

        &.confirm,
        &.order_comment_add,
        &.order_comment_edit,
        &.order_comment_detail,
        &.pay,
        &.create_tail,
        &.buy_again {
          border: 0px !important;
          background: #00c4a1;
          font-weight: 600;
          color: #f8fafa;
        }
      }
    }
  }

  .righticon {
    width: 34px;
    height: 34px;
    background: url("~@assets/images/orderlist/logoicon.png") center/cover no-repeat;
  }

  .shop {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    opacity: 1;
    line-height: 46px;
    background: #f8f8f8;
    border-radius: 8px 8px 0 0;
    padding: 0 20px;
    border-bottom: 1px solid #dee0e1;

    &.border {
      border-radius: 0;
      border-top: 1px solid #dee0e1;
    }

    .iconfont {
      color: #7a8087;
      display: inline-block;
      line-height: 1em;
    }

    .ziyinglogo {
      margin-left: 5px;
      width: 34px;
      height: 18px;
    }

    .leftbox {
      align-items: center;
      display: flex;
      margin-right: 100px;

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }

      .name {
        display: block;
        max-width: 250px;
        margin-right: 4px;
        font-size: 16px;
        font-weight: normal;
        color: #1a1a1a;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .status {
      width: max-content;
      font-size: 16px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #1a1a1a;

      &.pay {
        color: #ff3535;
      }
    }

    .shifu {
      position: absolute;
      left: 294px;
      font-size: 16px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #1a1a1a;
      line-height: 16px;

      .text {
        display: flex;
      }

      .price {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
}
</style>

<template>
  <div v-if="type !== 'split'" class="li">
    <itemlititle :item="item" @jumpShop="jumpShop" />
    <div class="item-box" v-if="item.order_type != 4 && (item.product_info || item.pro_info || item.pro)">
      <div v-if="nav == 105" class="item-li">
        <div class="img-p">
          <div class="img" @click="proDetails(item.pro_info.id)">
            <img :src="$imgUrlFilter(item.pro_info.wb_logo_1, imgWidth)" alt="" />
          </div>
        </div>
        <div class="name" @click="proDetails(item.pro_info.id)">
          <div class="name-text">
            {{ item.pro_info.name }}
          </div>
        </div>
        <div class="content">
          <template v-for="(items, ix) in [item.rew_info]" :key="ix">
            <p>
              {{ items.title }}
            </p>
            <div class="tode" @click="gotoDetails({ item })">查看详情</div>
            <div v-if="item.order_type == 2"></div>
            <div class="num" v-else-if="items.reward_num > 0">
              × {{ items.reward_num }}
            </div>
          </template>
        </div>
        <div class="price-box">
          <span class="text" v-if="nav == '105'">退款</span>
          <span class="text" v-else-if="item.order_list[0].status_data.status == 101 ||
            item.order_list[0].status_data.status == 102 ||
            item.order_list[0].status_data.status == 103
            ">
            应付
          </span>
          <span class="text" v-else>实付 </span>
          <span class="pic_p" v-if="nav == 105">¥{{ Number(item.await_price).toLocaleString("en-US") }}</span>
          <span class="pic_p" v-else>¥{{
            item.style == "merger"
            ? Number(item.apply_pay_amount).toLocaleString("en-US")
            : Number(item.order_list[0].pay_amount).toLocaleString("en-US")
          }}</span>
        </div>
      </div>
      <div v-else class="item-li">
        <div class="img-p">
          <div class="img" @click="proDetails(item.pro.product_info.pro_id)">
            <img :src="$imgUrlFilter(item.pro.product_info.logo, imgWidth)" alt="" />
          </div>
        </div>
        <div class="name" @click="proDetails(item.pro.product_info.pro_id )">
          <div class="name-text">
            {{ item.pro.product_info.name }}
          </div>
        </div>
        <div class="content">
          <template v-for="(items, ix) in item.pro.pay_items" :key="ix">
            <p>{{ items.item_title }}</p>
            <div class="tode" @click="gotoDetails({ item })">查看详情</div>
            <div class="num">{{ items.total }}</div>
          </template>
        </div>
        <div class="price-box">
          <span class="text" v-if="nav == '105'">退款</span>
          <span class="text" v-else>{{item.total_amount_title}} </span>
          <span class="pic_p" v-if="nav == 105">¥{{ Number(item.await_price).toLocaleString("en-US") }}</span>
          <span class="pic_p" v-else>{{item.total_amount_value}}</span>
        </div>
      </div>
    </div>
    <div class="item-box" v-else-if="item.mall">
      <div class="item-ul" v-for="(e, ix) in item.mall.shop_info.skus" :key="ix">
        <div class="item-li">
          <div class="img-p">
            <div class="img" @click="mallDetails(e.product_id)">
              <img :src="$imgUrlFilter(e.product_img, imgWidth)" alt="" />
            </div>
          </div>
          <div class="name" @click="mallDetails(e.product_id)">
            <div class="name-text">
              <div class="yushou" v-if="e.presale_type != 0"></div>
              {{ e.product_name }}
            </div>
          </div>
          <div class="content">
            <p>{{ e.sku_specs }}</p>
            <div class="tode" v-if="nav != 105" @click="gotoDetails({ item })">查看详情</div>
            <div class="num">x{{ e.num }}</div>
          </div>
          <div class="price-box">
            <div class="p">¥{{ e.sku_price }}</div>
            <div class="tips" v-if="item.presale_type != 0">
              {{ e.deliver_forecast_time }}
            </div>
          </div>
        </div>
        <template v-if="e.gifts && e.gifts.length">
          <div class="item-li gift-box" v-for="(e, ix) in e.gifts" :key="ix">
            <div class="img-p">
              <div class="img">
                <img :src="$imgUrlFilter(e.product_img, imgWidth)" alt="" />
              </div>
            </div>
            <div class="name">
              <div class="tedian">特典</div>
              {{ e.product_name }}
            </div>
            <div class="content">
              <p>{{ e.sku_specs }}</p>
              <div class="num">x{{ e.num }}</div>
            </div>
            <div class="price-box">
              <div class="p">¥{{ e.sku_price }}</div>
              <div class="tips" v-if="item.presale_type != 0">
                {{ e.deliver_forecast_time }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  env,
  // eslint-disable-next-line no-unused-vars
  domTop,
  // eslint-disable-next-line no-unused-vars
  domHeight,
  // eslint-disable-next-line no-unused-vars
  domClientHeight,
} from "md-base-tools/env";
import itemlititle from "@/components/order/itemlititle";
import domain from '@/service/domain'

export default {
  name: "itemli",
  data() {
    return {
      env,
    };
  },
  components: {
    itemlititle,
  },
  watch: {},
  computed: {
    imgWidth() {
      return 80;
    },
  },
  props: {
    item: {
      type: [Object, undefined],
      default: undefined,
    },
    nav: {
      type: Number,
      default: 100,
    },
    type: {
      type: String,
      default: "100",
    },
    listIndex: {
      type: Number,
      default: NaN,
    },
  },
  mounted() {
    this.$nextTick(() => { });
  },
  methods: {
    show_dialog(e) {
      console.log(e);
      this.$emit("show_dialog", e);
    },
    op(e) {
      e.listIndex = this.listIndex;
      this.$emit("op", e);
    },
    gotoDetails(e) {
      console.log(e);
      this.$emit("gotoDetails", e);
    },
    // 项目详情
    proDetails(pro_id) {

      window.open(`${domain.zhongchou}/item/${pro_id}.html`)

    },
    // 电商详情
    mallDetails(product_id) {

      if(this.item.extract_card_id &&
         this.item.extract_card_id > 0) {

          window.open(`${domain.wap}/luckycards/fastsell/detail/${this.item.extract_card_id}.html`)

      } else {
        window.open(`${domain.wap}/product/${product_id}.html`)
      }
    },
    jumpShop(id) {
      location.href = "/product/pop/" + id + ".html";
    },
    clickfn(type) {
      // eslint-disable-next-line vue/no-mutating-props
      this.pay_resource = type;
    },
    payClick() {
      this.$emit("payClick", this.pay_resource);
    },
  },
};
</script>
