<template>
  <div>
    <CompHeader title="我的订单" />
    <div class="nav-list">
      <el-tabs v-model="state.nav" @tab-change="navTab">
        <el-tab-pane v-for="(item, idx) in state.titleList" :key="idx" :label="`${item.title} ${item.code == 100 ? '' : item.count || ''}`"
          :name="item.code"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="order-list">
      <template v-if="state.list && state.list.length">
        <template v-for="(item, index) in state.list" :key="item.order_id">
          <itemli :item="item" :listIndex="index" :nav="Number(state.nav)" @gotoDetails="gotoDetails" @op="op"
            @show_dialog="show_dialog" />
          <itemli v-if="item.order_type !== 4 &&
            item.style == 'split' &&
            item.order_list.length > 1
            " :listIndex="index" type="split" :item="item" :nav="Number(state.nav)" @gotoDetails="gotoDetails" @op="op"
            @show_dialog="show_dialog" />
        </template>
        <div class="pagination">
          <el-pagination center background v-model:current-page="state.page" :hide-on-single-page="true"
            @current-change="getList" :page-size="10" layout="prev, pager, next, jumper" :total="state.total" />
        </div>
      </template>
      <div class="noorder" v-else>
        <div class="content">
          <div class="noimg"></div>
          <p>暂无订单</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  // eslint-disable-next-line no-unused-vars
  watch,
  onMounted,
  reactive,
  // eslint-disable-next-line no-unused-vars
  defineExpose,
  // eslint-disable-next-line no-unused-vars
  computed,
  onBeforeUnmount,
} from "vue";
// eslint-disable-next-line no-unused-vars
import { getNewOrderTitleList, getOrderList, getScheme, getOrderListV2 } from "@api";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import itemli from "@@/order/itemli.vue";
import CompHeader from "@@/common/header.vue";
import domain from '@/service/domain'
// eslint-disable-next-line no-unused-vars
const store = useStore();
const router = useRouter();
const route = useRoute();
const state = reactive({
  loading: true,
  list: [],
  page: 1,
  postCount: 0,
  total: 0,
  all_count: 0,
  nav: 100,
  titleList: [
    {
      title: "全部",
      code: 100,
      count: 0
    }
  ]
});
onMounted(async () => {
  await getTitle();
  if (route.query.code) {
    state.nav = Number(route.query.code);
  }
  if (localStorage.getItem("orderpage")) {
    state.nav = Number(localStorage.getItem("ordernav"));
    state.page = Number(localStorage.getItem("orderpage"));
    state.titleList.forEach((e) => {
      if (e.code == state.nav) {
        state.total = e.count;
      }
    });
    if(state.nav == 100){
      state.total == state.all_count;
    }
    localStorage.removeItem("orderpage");
    localStorage.removeItem("ordernav");
    getList(state.page);
  } else {
    getList();
  }
  // lowerdev.addbind(window, "scroll", reloadScroll);
});
onBeforeUnmount(() => {
  // lowerdev.removebind(window, "scroll", reloadScroll);
});
const getTitle = async () => {
  try {
    const { data } = await getNewOrderTitleList({ json_type: "json" });
    for (var key in data){
      state.all_count += data[key].count
      state.titleList.push(data[key])
    }
  } catch (e) {
    console.log(e);
  }
};
// eslint-disable-next-line no-unused-vars
const getList = async (e) => {
  if (e) state.page = e;
  const { data } = await getOrderListV2({
    page: state.page,
    keyword: '',
    status: String(state.nav),
    page_size: 10
  });
  const arr = data.list;
  state.total = data.total;
  if (arr && arr.length) {
    state.list = arr;
    state.loading = false;
  }
};
const gotoDetails = ({ item, type }) => {
  let orderId = "";
  if (type === "split") {
    orderId = item.order_id || item.order_list[1].order_id;
  } else {
    orderId = item.order_id || item.order_list[0].order_id;
  }
  localStorage.setItem("orderpage", state.page);
  localStorage.setItem("ordernav", state.nav);
  if (item.order_type !== 4) {
    if (item.style === "merger") {
      router.push(
        `/order/project_order_detail?order_id=${item.pay_id}&style=merger`
      );
    } else {
      router.push(`/order/project_order_detail?order_id=${orderId}`);
    }
  } else {
    router.push(`/order/product_order_detail?order_id=${orderId}`);
  }
};
async function navTab(item) {
  if(item == 105){
    window.location.href = `${domain.wap}/order/order_list??after=true&nav=200`;
    return false;
  }
  let att = state.titleList.filter(e => e.code === item)
  state.nav = item;
  state.list = [];
  state.page = 1;
  await getList();
  state.total = att[0].count;
}
// eslint-disable-next-line no-unused-vars
const reloadScroll = () => {
  const localTop = document.documentElement.scrollTop || 0;
  const domHeight = document.documentElement.scrollHeight || 0;
  const domClientHeight = document.documentElement.clientHeight || 0;

  if (state.loading) {
    return false;
  }
  if (localTop && domHeight < localTop + domClientHeight + 50) {
    state.loading = true;
    state.page++;
    getList();
  }
};
// eslint-disable-next-line no-unused-vars
const lowerdev = {
  addbind(element, type, handler) {
    if (element.addEventListener) {
      element.addEventListener(type, handler, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + type, handler);
    } else {
      element["on" + type] = handler;
    }
  },
  removebind(element, type, handler) {
    if (element.removeEventListener) {
      element.removeEventListener(type, handler, false);
    } else if (element.detachEvent) {
      element.detachEvent("on" + type, handler);
    } else {
      element["on" + type] = null;
    }
  },
};
</script>
<style  lang="scss" scoped>
.nav-list {
  height: 76px;
  display: flex;
  align-items: center;

  // border-bottom: 2px solid #e8e8e8;
  .el-tabs {
    width: 100%;
  }

  ::v-deep .el-tabs__header {
    margin-bottom: 0;
  }

  .nav-li {
    height: 100%;
    font-size: 16px;
    font-weight: normal;
    color: #1a1a1a;
    line-height: 76px;
    position: relative;
    margin-right: 33px;

    &.li {
      font-weight: bolder;
      color: #1a1a1a;
    }

    .cli {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 24px;
      height: 6px;
      background: #222c37;
      border-radius: 24px;
    }
  }
}

.noorder {
  padding-top: 122px;

  .noimg {
    margin: 0 auto;
    width: 130px;
    height: 130px;
    background: url("https://p6.moimg.net/mdwxapp/empty_order_icon.png") center/cover;
  }

  p {
    text-align: center;
    margin-top: 10px;
    height: 12px;
    font-size: 12px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #9e9e9e;
    line-height: 12px;
  }
}

.pagination {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 20px;
}

// .order-list {
//     height: calc(100vh - 300px);
//     overflow: auto;
//   }
</style>
