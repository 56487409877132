<style lang="scss" scoped>
.shop {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  background: #f8f8f8;
  border-radius: 8px 8px 0 0;
  opacity: 1;
  border-bottom: 1px solid #dee0e1;
  line-height: 46px;
  padding: 0 20px;
  .iconfont {
    color: #7a8087;
    display: inline-block;
    line-height: 1em;
  }
  .ziyinglogo {
    margin-left: 5px;
    width: 34px;
    height: 18px;
  }
  .leftbox {
    align-items: center;
    display: flex;
    margin-right: 100px;
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
    .name {
      display: block;
      max-width: 250px;
      margin-right: 4px;
      font-size: 16px;
      font-weight: bolder;
      color: #1a1a1a;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .status {
    width: max-content;
    font-size: 16px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    &.pay {
      color: #ff3535;
    }
  }
  .shifu {
    position: absolute;
    left: 294px;
    font-size: 16px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    line-height: 16px;
    .text {
      display: flex;
    }
    .price {
      font-weight: bold;
      margin-left: 5px;
    }
  }
}
</style>

<template>
  <div class="shop" v-if="item.order_type != 4">
    <div class="leftbox">
      <img
        class="icon"
        v-if="item.order_type == 1"
        src="~@assets/images/orderlist/orderlistyunyang.png"
      />
      <img
        v-else
        class="icon"
        src="~@assets/images/orderlist/orderlistzhongchou.png"
      />
      <div class="name">
        {{
          item.order_type == 1
            ? "云养计划"
            : item.order_type == 3
            ? "课程"
            : "众筹"
        }}
      </div>
    </div>

    <template v-if="nav == 105">
      <div :class="['status']" v-if="item.refund_status_zh">
        {{ item.refund_status_zh }}
      </div>
    </template>
    <template v-if="item.status_data">
      <div
        :class="[
          'status',
          { pay:item.status_data.status == 101 },
        ]"
      >
        {{ item.status_data.status_name }}
      </div>
    </template>
  </div>
  <div
    class="shop"
    @click.stop="jumpShop(item.mall.shop_info.shop_id)"
    v-if="item.order_type == 4 && item.mall"
  >
    <div class="leftbox">
      <img class="icon" src="~@assets/images/orderlist/orderlistshop.png" />
      <div class="name">
        {{ item.mall.shop_info.name }}
      </div>
      <img
        class="ziyinglogo"
        v-if="item.mall.shop_info.official != '0'"
        src="~@assets/images/orderlist/ziyinglogo.png"
      />
    </div>
    <div class="shifu" v-if="item.total_amount_title">
      共{{ item.mall.shop_info && item.mall.shop_info.skus.length }}件商品
      <span class="text_P" v-if="nav == 105">退款</span>
      <span class="text_P" v-else>，{{item.total_amount_title}}</span>
      <span class="price">{{item.total_amount_value}}</span>
    </div>
    <div :class="['status', { pay: item.status_data.status == 101 }]" v-if="item.status_data">
      {{ item.status_data.status_name }}
    </div>
  </div>
</template>

<script>
import { env } from "md-base-tools/env";
export default {
  name: "itemlititle",
  data() {
    return {
      env,
    };
  },
  components: {},
  watch: {},
  computed: {},
  props: {
    item: {
      type: [Object, undefined],
      default: undefined,
    },
    nav: {
      type: Number,
      default: 100,
    },
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    jumpShop(id) {
      // location.href = "/product/pop/" + id + ".html";
    },
    clickfn(type) {
      // eslint-disable-next-line vue/no-mutating-props
      this.pay_resource = type;
    },
  },
};
</script>
